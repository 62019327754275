














import { Component } from 'vue-property-decorator'
import myMixins from '@/mixins/common.ts'
import API from '@/api/index.js'
import Util from '@/utils/index.js'
import InputBox from '../../common/input-box/inputBox.vue'

@Component({
    components: {
        InputBox
    }
})
export default class LastPhone extends myMixins {
    phone = ''
    canClick = false
    action = 4 //  1 注册 2 登录 3 重置密码 4 解绑手机 5 绑定手机

    valueChange(params: Param) {
        const { name, val } = params
        this[name] = val
        this.canClick = Util.checkPhone(this.phone)
    }

    // 手机登录发送验证码
    async sendYzm() {
        if (this.canClick) {
            this.$store.commit('login/updateTimeNumber', -1)
            this.getYZM()
            const res = await API.sendForYzm({
                mobile: this.phone,
                action: this.action
            })
            if (res && res.data && res.data.errorCode === 200) {
                this.$store.commit('login/updateTimePhone', this.phone)
                this.$store.commit('login/updateTimeAction', this.action)
                this.$nextTick(() => {
                    this.routerChange('verificationCode')
                })
            }
        }
    }
}
